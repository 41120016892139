export const integrations = {
  BOMBAS: 'Bombas',
  GORGIAS: 'Gorgias',
  GORGIAS_CHAT: 'Gorgias Chat',
  HAPPY_RETURNS: 'Happy Returns',
  KLAVIYO: 'Klaviyo',
  MAILGUN: 'Mailgun',
  OLIVE: 'Olive',
  OMNISEND: 'Omnisend',
  RECHARGE: 'Recharge',
  SFN: 'Shopify Fulfillment Network',
  SHIPBOB: 'ShipBob',
  SHIPHERO: 'ShipHero',
  SHOPIFY_POS: 'Shopify POS',
  SIGNIFYD: 'Signifyd',
  VEHO: 'Veho',
  ZENDESK_CHAT: 'Zendesk Chat',
  RISE: 'Rise',
  STRIPE: 'Stripe',
  MALOMO: 'Malomo',
  BRIGHTPEARL: 'Brightpearl',
  PEOPLEVOX: 'PeopleVox',
  REBUY: 'Rebuy',
  TRYNOW: 'TryNow',
  POSHMARK: 'Poshmark',
  RYDER_SHIP: 'RyderShip',
};
